import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="World's Longest Ice Cream Sundae" 
        description="In 2014 Boice Bros. hosted its official 100th Anniversary Party by inviting the entire community to help create the longest ice cream sundae in the world. When completed the ice cream sundae was 1,666 feet long!."
      />
      <div className="page longest"><div className="container"><div className="inner">

        <h2 className="color_pink">World's  Longest  Ice Cream Sundae</h2>



        <p>In 2014 Boice Bros. hosted its official 100th Anniversary Party by inviting the entire community to help create
        the longest ice cream sundae in the world. 
        Over 5,000 people descended upon Kingstons’ Rondout promenade to enjoy entertainment, help make the sundae, watch and eat!  When completed the ice 
        cream sundae was 1,666 feet long! And contained
        vanilla ice cream, whipped cream, chocolate syrup, sprinkles and cherries!</p>


        <div className="bottom_text">
        <p>
          The event raised $16,932 to fund the startup of a new program for children – the Hudson River Stewards – managed by the HV Maritime Museum and the Clearwater Organization.
        </p>

        </div>

      </div></div></div>
    </Layout>
  )



}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
